import { cva } from 'class-variance-authority';
import { AlertStatus } from './Alert.types';

export const alertCVA = cva(['flex content-center w-auto rounded-dt-radius-small'], {
  variants: {
    variant: {
      alertBox: undefined,
      inputFieldAlertStandalone: ['items-start', 'sm:items-center'],
      inputFieldAlertAttached: ['items-start', 'sm:items-center'],
    },
    device: {
      web: 'p-dt-spacing-200',
      tv: 'p-dt-spacing-400',
    },
    status: {
      error: undefined,
      hint: undefined,
      info: undefined,
      success: undefined,
      warning: undefined,
    },
  },
  compoundVariants: [
    {
      variant: ['inputFieldAlertAttached'],
      device: 'tv',
      className: [
        'rounded-tl-dt-radius-none rounded-tr-dt-radius-none',
        'rounded-bl-dt-radius-medium rounded-br-dt-radius-medium',
        'px-dt-spacing-300',
        'pt-dt-spacing-350',
        'pb-dt-spacing-250',
      ],
    },
    {
      variant: ['inputFieldAlertStandalone'],
      device: 'tv',
      className: ['rounded-dt-radius-medium', 'p-dt-spacing-400'],
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: 'p-dt-spacing-100',
    },
    {
      variant: 'inputFieldAlertAttached',
      device: 'web',
      className: [
        'rounded-tl-dt-radius- rounded-tr-dt-radius-none',
        'rounded-bl-dt-radius-small rounded-br-dt-radius-small',
      ],
    },
    {
      variant: 'inputFieldAlertStandalone',
      device: 'web',
      className: 'rounded-dt-radius-small',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'error',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-error',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'hint',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'info',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-info',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'success',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-success',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      status: 'warning',
      device: 'web',
      className: 'bg-dt-theme-surface-input-field-alert-input-field-alert-warning',
    },
    {
      variant: ['alertBox'],
      status: 'error',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-error',
    },
    {
      variant: ['alertBox'],
      status: 'hint',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box',
    },
    {
      variant: ['alertBox'],
      status: 'info',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-info',
    },
    {
      variant: ['alertBox'],
      status: 'success',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-success',
    },
    {
      variant: ['alertBox'],
      status: 'warning',
      device: 'web',
      className: 'bg-dt-theme-surface-alert-box-alert-box-warning',
    },
    {
      status: Object.values(AlertStatus),
      device: 'tv',
      className: 'bg-dt-theme-tv-surface-alert-alert',
    },
  ],
});

export const alertIconCVA = cva('flex', {
  variants: {
    variant: {
      alertBox: ['[&>svg]:w-20 [&>svg]:h-20'],
      inputFieldAlertStandalone: ['[&>svg]:w-16', '[&>svg]:h-16]'],
      inputFieldAlertAttached: ['[&>svg]:w-16', '[&>svg]:h-16]'],
    },
    device: {
      web: ['[&>svg]:w-20 [&>svg]:h-20'],
      tv: ['[&>svg]:w-36 [&>svg]:h-36'],
    },
    hasTitle: {
      true: 'self-start',
      false: ['mt-0 mb-0'],
    },
  },
  compoundVariants: [
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'tv',
      className: ['[&>svg]:w-40 [&>svg]:h-40'],
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className: ['[&>svg]:w-16 [&>svg]:h-16'],
    },
  ],
});

export const alertTextCVA = cva('flex flex-col mt-auto mb-auto font-dt-font-family-system', {
  variants: {
    variant: {
      alertBox: '[&>svg]:w-20 [&>svg]:h-20',
      inputFieldAlertStandalone: ['[&>svg]:w-16 [&>svg]:h-16]'],
      inputFieldAlertAttached: ['[&>svg]:w-16 [&>svg]:h-16]'],
    },
    device: {
      web: ['mx-dt-spacing-150'],
      tv: ['mx-dt-spacing-300 text-dt-font-size-30 leading-dt-font-line-height-36'],
    },
    hasTitle: {
      true: 'leading-normal',
    },
  },
  compoundVariants: [
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'web',
      className:
        'text-dt-font-size-14 leading-dt-font-line-height-18 text-dt-theme-text-input-field-alert-input-field-alert',
    },
    {
      variant: ['alertBox'],
      device: 'web',
      className: 'text-dt-theme-text-alert-box-alert-box',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: 'tv',
      className: 'text-dt-theme-tv-text-input-field-alert-input-field-alert',
    },
    {
      variant: ['alertBox'],
      device: 'tv',
      className: 'text-dt-theme-tv-text-alert-box-alert-box',
    },
    {
      variant: ['inputFieldAlertStandalone', 'inputFieldAlertAttached'],
      device: ['tv', 'web'],
      hasTitle: false,
      className: 'leading-none',
    },
  ],
});

const defaultAlertDeleteStyle = [
  'border-dt-border-none outline-none bg-transparent p-dt-spacing-none',
  'ml-auto cursor-pointer h-fit',
  '[&>svg]:fill-dt-theme-icon-alert-box-icon',
];

export const alertDeleteCVA = cva(defaultAlertDeleteStyle, {
  variants: {
    hasTitle: {
      true: 'self-top',
    },
  },
});
